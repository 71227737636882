import "../Style/All.css";

export default function Test() {
  return (
    <div>
      <div>
        <button className="btn1">버튼1 _ 일반</button>
      </div>
      <div>
        <button className="btn2">버튼2 _ 강조</button>
      </div>
      <div>
        <button className="btn3">버튼3 _ 기타(회색)</button>
      </div>
      <div>
        <button className="btn4">버튼 4 _ 기타(검정)</button>
      </div>
    </div>
  );
}
